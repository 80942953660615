import { NgForOf } from '@angular/common'
import { Component, TemplateRef, ViewChild } from '@angular/core'
import { E11CardComponent } from '@engineering11/ui-lib/e11-card'
import { E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'job-automation-requested-update-helper',
  standalone: true,
  imports: [TranslateModule, E11CardComponent, NgForOf],
  template: `
    <span (click)="openModal()" class="e11-text-skin-primary-accent e11-cursor-pointer">{{ 'More Information' }}</span>
    @defer () {
    <ng-template #content>
      <div class="e11-text-skin-primary-accent e11-flex e11-gap-2 e11-items-center e11-mb-4">
        <span class="material-icons md-24">auto_mode</span>
        <h3>{{ 'Automatically review applications and request missing information' | translate }}</h3>
      </div>
      <p>
        {{
          'Automate the laborious task of reviewing applications and asking for important pieces of information that were not included when the application was submitted. Choose the specific sections you want more information on and let this feature handle the rest.'
            | translate
        }}
      </p>
      <div class="e11-mb-4">
        <h3 class="e11-mb-4">{{ 'All you need to do' | translate }}</h3>
        <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-2 e11-gap-4">
          <e11-card [isClickable]="false" *ngFor="let item of needToKnow">
            <div class="e11-flex e11-gap-2 e11-items-center e11-mb-4">
              <span class="material-icons-outlined e11-text-skin-primary-accent">{{ item.icon }}</span>
              <p class="e11-font-primary-demibold e11-mb-0">{{ item.title | translate }}</p>
            </div>
            <p>
              {{ item.description | translate }}
            </p>
          </e11-card>
        </div>
      </div>
      <div class="e11-mb-4">
        <h3 class="e11-mb-4">{{ 'What happens' | translate }}</h3>
        <e11-card [isClickable]="false" containerClass="e11-grid e11-grid-cols-1 md:e11-grid-cols-3 e11-gap-4">
          <div *ngFor="let item of whatHappens">
            <div class="e11-flex e11-gap-2 e11-items-center e11-mb-4">
              <span class="material-icons-outlined e11-text-skin-primary-accent">{{ item.icon }}</span>
              <p class="e11-font-primary-demibold e11-mb-0">{{ item.title | translate }}</p>
            </div>
            <div class="e11-bg-skin-grey/20 e11-rounded e11-p-4 md:e11-h-36">
              <p>{{ item.description | translate }}</p>
            </div>
          </div>
        </e11-card>
      </div>
    </ng-template>
    }
  `,
  styles: ``,
})
export class AutomationRequestedUpdateHelperComponent {
  @ViewChild('content') content!: TemplateRef<any>
  needToKnow = [
    {
      icon: 'edit',
      title: 'Select Important Sections',
      description:
        'Pick the section(s) of the applications that you want this feature to review and if that information isn’t included on the application this feature will contact the applicant with your custom message.',
    },
    {
      icon: 'schedule',
      title: 'Choose What Happens Next',
      description: 'Once the application is updated by the candidate choose the status or swimlane you would like to apply to the application.',
    },
  ]

  whatHappens = [
    {
      icon: 'settings_suggest',
      title: 'Application Processed',
      description: 'The application is reviewed to see if all required information was supplied.',
    },
    {
      icon: 'chat',
      title: 'Applicant Contacted',
      description: 'The candidate is contacted using the message configuration requesting that they update their application. ',
    },
    {
      icon: 'task_alt',
      title: 'Information Updated',
      description: 'The application is updated and set in the status or swimlane configured in this feature.',
    },
  ]

  constructor(private modalService: E11ModalGlobalService) {}

  openModal() {
    this.modalService.openModal({
      body: this.content,
      title: 'Automation Configuration - Requested Updates',
      width: 'lg',
    })
  }
}
