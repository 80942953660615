export enum JobAutomationPermissions {
  CustomizeMessage = 'jobs-automation/customize-message',
  ManageAutomation = 'jobs-automation/manage-automation', // Company Admin
  SuperUserAutomation = 'jobs-automation/super-user-manage-automation', // System Admin
}

export const JOBS_AUTOMATION_MULTIMEDIA_CONFIG_OPTIONS = {
  maxFileSize: 300, // 300 MB
  maxNumberOfFiles: 8,
}

export const JOBS_AUTOMATION_MARKDOWN_CONFIG_OPTIONS = {
  footer: {
    action: [{ visible: false }],
    social: [{ visible: false }],
    audio: [
      { type: 'video', icon: 'videocam', visible: true },
      { visible: false, type: 'voice' },
      { visible: false, type: 'audio' },
    ],
  },
}
