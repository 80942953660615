import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { IAutomationForms } from '@employer/app/components/jobs-automation/job-automation-form.component'
import { ISendMessageFormData } from '@employer/app/components/jobs-automation/job-automation.model'
import { JobsPostAutomationCompanySettingsStore } from '@employer/app/modules/customer/views/jobs-post-automation-company-settings/jobs-post-automation-company-settings.store'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { deepCopy } from '@engineering11/utility'
import { LetDirective } from '@ngrx/component'
import { TranslateModule } from '@ngx-translate/core'
import { IRequestUpdatesFormData } from '../auto-request-updates.model'
import { IAutomationFormData } from '../automation-form.model'
import { E11ConfirmationModule } from '@engineering11/ui-lib/e11-confirmation'
import { IAutomatedQuestionSendMessage } from '@employer/app/modules/customer/views/jobs-post-automation-company-settings/auto-question-message.model'

export type AutomationFormData = ISendMessageFormData | IRequestUpdatesFormData | IAutomatedQuestionSendMessage
export type FormField = keyof IAutomationForms | 'autoQuestionInitial' | 'autoQuestionFollowUp' | 'autoQuestionWarning'

@Component({
  selector: 'automation-reset',
  standalone: true,
  imports: [TranslateModule, E11TooltipModule, LetDirective, CommonModule, E11ConfirmationModule],
  template: `
    <ng-container *ngrxLet="companyAutomationFormData$ as formData">
      <div class="e11-flex e11-gap-4 e11-text-sm e11-mb-4">
        <e11-tooltip
          [color]="'dark'"
          [tooltipText]="'This will reset the message content to what has been configured globally under your Company Settings.' | translate"
        >
          <button
            *ngIf="!loading && formData"
            (click)="confirmAutomationDeletion.open()"
            type="button"
            class="e11-text-skin-primary-accent e11-flex e11-items-center"
          >
            <span class="material-icons-outlined md-18">replay</span>
            Reset
          </button>
        </e11-tooltip>
      </div>

      <e11-confirmation
        #confirmAutomationDeletion
        (answer)="onResetFormData(formData, $event)"
        [backdropStyle]="'dark'"
        cancelText="{{ 'Cancel' | translate }}"
        confirmText="{{ 'Confirm' | translate }}"
        confirmTitle="{{ 'Reset message content?' | translate }}"
      >
        {{
          'You are about to reset your message text and attachments back to what has been configured in your Company Settings defaults. Do you want to continue?'
            | translate
        }}
      </e11-confirmation>
    </ng-container>
  `,
  styles: ``,
})
export class AutomationResetComponent {
  @Input({ required: true }) formField!: FormField
  @Input() loading = true

  @Output() onFormFieldReset = new EventEmitter<AutomationFormData>()

  companyAutomationFormData$ = this.jobsPostAutomationCompanySettingsStore.formData$

  constructor(private jobsPostAutomationCompanySettingsStore: JobsPostAutomationCompanySettingsStore) {}

  onResetFormData(companyData: IAutomationFormData, confirm: boolean) {
    if (!confirm) return
    const companyFormData = deepCopy(companyData)
    if (!companyFormData) return
    switch (this.formField) {
      case 'autoResponse':
        this.onFormFieldReset.emit(companyFormData.autoResponse)
        break
      case 'requestMoreInitial':
        this.onFormFieldReset.emit(companyFormData.requestMore.initialMessage)
        break
      case 'requestMoreFollowUp':
        this.onFormFieldReset.emit(companyFormData.requestMore.followUp)
        break
      case 'requestMoreWarning':
        this.onFormFieldReset.emit(companyFormData.requestMore.warning)
        break
      case 'requestedUpdate':
        this.onFormFieldReset.emit(companyFormData.requestMore.requestedUpdate)
        break
      case 'autoQuestionInitial':
        this.onFormFieldReset.emit(companyFormData.autoQuestions.defaultMessages?.initialMessage)
        break
      case 'autoQuestionFollowUp':
        this.onFormFieldReset.emit(companyFormData.autoQuestions.defaultMessages?.followUp)
        break
      case 'autoQuestionWarning':
        this.onFormFieldReset.emit(companyFormData.autoQuestions.defaultMessages?.warning)
        break
    }
  }
}
