import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { IFileReceipt } from '@engineering11/files-web'
import { E11CardModule } from '@engineering11/ui-lib/e11-card'

@Component({
  selector: 'automation-attachment-preview',
  standalone: true,
  imports: [TranslateModule, CommonModule, E11CardModule],
  template: `
    <div class="e11-flex e11-flex-row e11-gap-2 e11-flex-wrap e11-my-2" *ngIf="_fileReceipts?.length">
      <div class="e11-relative e11-group e11-w-56" *ngFor="let fileReceipt of _fileReceipts">
        <ng-container [ngSwitch]="fileReceipt.iconType">
          <video
            *ngSwitchCase="'video'"
            class="e11-rounded-md e11-ring-1 e11-ring-gray-300 hover:e11-bg-gray-100"
            [src]="fileReceipt.file.url"
            controls
            width="250"
            alt=""
          >
            <source [src]="fileReceipt.file.url" [type]="fileReceipt.file.mimeType" />
          </video>
          <div *ngSwitchCase="'image'" class="e11-w-full e11-max-h-32 e11-overflow-hidden">
            <img
              class="e11-w-full e11-bg-red-500 e11-rounded-md e11-ring-1 e11-ring-gray-300 hover:e11-bg-gray-100"
              [src]="fileReceipt.file.url"
              alt=""
            />
          </div>
          <e11-card *ngSwitchDefault class="e11-w-full e11-h-32 e11-overflow-hidden e11-flex e11-justify-center e11-items-center">
            <div class="e11-flex e11-gap-2 e11-flex-col e11-items-center e11-justify-center">
              <span class="material-icons e11-text-4xl"> attachment </span>
              <span class="e11-sr-only">{{ fileReceipt.iconType }}</span>
              <span class="e11-truncate e11-max-w-xs"> {{ fileReceipt.file.fileName }}</span>
            </div>
          </e11-card>
        </ng-container>
        <span
          *ngIf="showRemoveButton"
          class="e11-absolute e11-cursor-pointer e11-hidden group-hover:e11-block e11-top-0 e11-right-1 e11-h-5 e11-w-5 e11--translate-y-1/2 e11-translate-x-1/2 e11-transform e11-rounded-full e11-bg-white"
          (click)="onRemoveFileReceipt(fileReceipt.file.id)"
        >
          <span class="material-icons e11-text-gray-400 e11-text-[20px]">cancel</span>
        </span>
      </div>
    </div>
  `,
})
export class AutomationAttachmentPreviewComponent {
  _path: string[] = []
  _fileReceipts: { file: IFileReceipt; iconType: string }[] = []
  @Input() set fileReceipts(values: IFileReceipt[] | undefined) {
    if (!values) return
    this._fileReceipts = Array.from(values).map(value => ({
      file: value,
      iconType: this.findFileIcon(value.mimeType),
    }))
  }
  @Input() showRemoveButton = true
  @Output() removeFileReceipt = new EventEmitter<string>()

  findFileIcon(mimeType: string) {
    const type = mimeType.split('/')[0]
    return type || 'unknown'
  }

  onRemoveFileReceipt(fileReceiptId: string) {
    this.removeFileReceipt.emit(fileReceiptId)
    this._fileReceipts = this._fileReceipts.filter(f => f.file.id !== fileReceiptId)
  }
}
