import { NgForOf } from '@angular/common'
import { Component, TemplateRef, ViewChild } from '@angular/core'
import { E11CardComponent } from '@engineering11/ui-lib/e11-card'
import { E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'job-automation-auto-response-helper',
  standalone: true,
  imports: [TranslateModule, E11CardComponent, NgForOf],
  template: `
    <span (click)="openModal()" class="e11-text-skin-primary-accent e11-cursor-pointer">{{ 'More Information' }}</span>
    @defer () {
    <ng-template #content>
      <div class="e11-text-skin-primary-accent e11-flex e11-gap-2 e11-items-center e11-mb-4">
        <span class="material-icons md-24">auto_mode</span>
        <h3>{{ 'Automatically greet your applicants and get them engaged' | translate }}</h3>
      </div>
      <p>
        {{
          'The Application Auto Response part of our “Automation” feature lets you configure each Job Post to automatically respond to every application. It’s very simple to use and extremely useful in gauging candidate engagement as well as encouraging more communication. '
            | translate
        }}
      </p>
      <div class="e11-mb-4">
        <h3 class="e11-mb-4">{{ 'All you need to do' | translate }}</h3>
        <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-2 e11-gap-4">
          <e11-card [isClickable]="false" *ngFor="let item of needToKnow">
            <div class="e11-flex e11-gap-2 e11-items-center e11-mb-4">
              <span class="material-icons-outlined e11-text-skin-primary-accent">{{ item.icon }}</span>
              <p class="e11-font-primary-demibold e11-mb-0">{{ item.title | translate }}</p>
            </div>
            <p>
              {{ item.description | translate }}
            </p>
          </e11-card>
        </div>
      </div>
      <div class="e11-mb-4">
        <h3 class="e11-mb-4">{{ 'What happens' | translate }}</h3>
        <div>
          <e11-card [isClickable]="false">
            <div class="e11-flex e11-gap-2 e11-items-center e11-mb-4">
              <span class="material-icons-outlined e11-text-skin-primary-accent">chat</span>
              <p class="e11-font-primary-demibold e11-mb-0">{{ 'Applicants Are Welcome And Engaged' | translate }}</p>
            </div>
            <p>
              {{
                'As applications are received your message is sent to the candidate at the time you selected.  You can have the message sent immediately or configure it to be sent minutes or even hours later to give it a more personalized experience. Use this feature to not only welcome candidates but to gauge their interest in the job as well as your company.'
                  | translate
              }}
            </p>
          </e11-card>
        </div>
      </div>
    </ng-template>
    }
  `,
  styles: ``,
})
export class AutomationAutoResponseHelperComponent {
  @ViewChild('content') content!: TemplateRef<any>
  needToKnow = [
    {
      icon: 'edit',
      title: 'Write Your Message',
      description:
        'Use our default messaging or write your own custom welcome message. This message will be sent to everyone that applies to a job post.',
    },
    {
      icon: 'schedule',
      title: 'Pick A Time To Send',
      description:
        'Configure a time for your message to be sent after the submission of the application. Delay the sending of this message to fit your specific needs.',
    },
  ]

  constructor(private modalService: E11ModalGlobalService) {}

  openModal() {
    this.modalService.openModal({
      body: this.content,
      title: 'Automation Configuration - Application Auto Response',
      width: 'lg',
    })
  }
}
