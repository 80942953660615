import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { ResponsiveWarningComponent } from '@candidate/app/modules/_shared/components/responsive-warning/responsive-warning.component'
import { ERROR_HANDLING } from '@employer/app/app.constants'
import { CandidateShareFormComponent } from '@employer/app/components/algolia/ui-alg-card/ui-candidate-share-form.component'
import { UiCandidateStarsComponent } from '@employer/app/components/algolia/ui-alg-card/ui-candidate-stars.component'
import { CustomerNavComponent } from '@employer/app/components/custom-navs/customer-nav/customer-nav.component'
import { MessagingNavComponent } from '@employer/app/components/custom-navs/messaging-nav/messaging-nav.component'
import { FormSideoverContainerComponent } from '@employer/app/components/form-sideover-container/form-sideover-container.component'
import { MainLayoutComponent } from '@employer/app/components/layout/main-layout.component'
import { MobileDrawerIconComponent } from '@employer/app/components/mobile-drawer-icon/mobile-drawer-icon.component'
import { FormModalContainerComponent } from '@employer/app/components/modal-container/form-modal-container.component'
import { UiTemplate } from '@employer/app/components/modal-container/ui-template'
import { DirectMessagesNavComponent } from '@employer/app/components/nav-components/direct-messages-nav/direct-messages-nav.component'
import { JobApplicationMessagesNavComponent } from '@employer/app/components/nav-components/job-application-messages-nav/job-application-messages-nav.component'
import { AppNavContainerComponent } from '@employer/app/components/nav-container/nav-container.component'
import { ProfileDropdownComponent } from '@employer/app/components/profile-dropdown/profile-dropdown.component'
import { SwimlaneWatchConfigFormComponent } from '@employer/app/components/swimlane-watch-config-form/swimlane-watch-config-form.component'
import { AccessConfigProvider } from '@employer/app/config/access.config'
import { EmployerApplicationConversationProvider } from '@employer/app/config/conversation-config-provider/application-conversation-config.provider'
import { EmployerApplicationConversationDMProvider } from '@employer/app/config/conversation-config-provider/application-conversation-dm-config.provider'
import { ConversationCustomDataProvider, MessagingConfigFromApp } from '@employer/app/config/messaging.config'
import { CheckFieldIsUnfulfilledPipe } from '@employer/app/pipes/check-field-is-unfulfilled.pipe'
import { CheckStateRequiresSalaryTransparencyPipe } from '@employer/app/pipes/check-state-requires-salary-transparency.pipe'
import { DocumentIdToContentIdPipe } from '@employer/app/pipes/document-id-to-content-id.pipe'
import { IsApplicationArchivedPipe } from '@employer/app/pipes/is-application-archived.pipe'
import { ApplicationCommentNotificationHandler } from '@employer/app/services/notifications/application-comment-notification.handler'
import { ApplicationReviewCreatedNotificationHandler } from '@employer/app/services/notifications/application-review-created-notification.handler'
import { CandidateAppliedNotificationHandler } from '@employer/app/services/notifications/candidate-applied-notification.handler'
import { EmployerApplicationResubmittedHandler } from '@employer/app/services/notifications/employer-application-resubmitted.handler'
import { MessagingUserMentionedEmployerHandler } from '@employer/app/services/notifications/messaging-user-mentioned-employer.handler'
import { RequestedUpdateFulfilledNotificationHandler } from '@employer/app/services/notifications/requested-update-fulfilled-notification-handler'
import { SwimlaneStatusUpdatedHandler } from '@employer/app/services/notifications/swimlane-status-updated.handler'
import { VirtualDialogueSubmittedEmployerHandler } from '@employer/app/services/notifications/virtual-dialogue-submitted-employer.handler'
import { environment } from '@employer/environments/environment'
import { AccessModule } from '@engineering11/access-web'
import { NgAisModule } from '@engineering11/angular-instantsearch'
import { CompanySessionTimeoutComponent, CustomerClientModule } from '@engineering11/customer-web'
import { MessagingConfigurationProvider, MessagingModule } from '@engineering11/messaging-web'
import { NotificationHandlerModule, UiNotificationComponent, UiNotificationLongComponent } from '@engineering11/notifications-web'
import { E11SummernoteViewDirective } from '@engineering11/ui-lib/e11-directives'
import { E11InputErrorsModule } from '@engineering11/ui-lib/e11-input-errors'
import { WebUtilityModule } from '@engineering11/web-utilities'
import { LetDirective } from '@ngrx/component'
import { TranslateModule } from '@ngx-translate/core'
import { NgxSummernoteModule } from 'ngx-summernote'
import {
  EmployerJobPostClosedNotificationHandler,
  EmployerJobPostReactivatedNotificationHandler,
  HiringTeamMemberAddNotificationHandler,
  NotificationConfigProvider,
  SharedLibModule,
  SocialDmConfigProvider,
} from 'shared-lib'
import { UiAlgCardComponent } from '../../components/algolia/ui-alg-card/ui-alg-card.component'
import { UiAlgLineItemComponent } from '../../components/algolia/ui-alg-line-item/ui-alg-line-item.component'
import { UiRangeSliderListComponent } from '../../components/algolia/ui-range-slider/ui-range-slider.component'
import { UiRefinementListComponent } from '../../components/algolia/ui-refinement-list/ui-refinement-list.component'
import { AppFooterComponent } from '../../components/footer/footer.component'
import { AppHeaderLandingComponent } from '../../components/header-landing/header-landing.component'
import { AppHeaderLoginComponent } from '../../components/header-login/header.component'
import { AppHeaderRegisterComponent } from '../../components/header-register/header-register.component'
import { AppHeaderComponent } from '../../components/header/header.component'
import { LoaderViewComponent } from '../../components/loader-view/loader-view.component'
import { AppLogoComponent } from '../../components/logo/logo.component'
import { ModalPrivacyPolicyComponent } from '../../components/modals/privacy-policy/privacy-policy.component'
import { ModalTermsOfUseComponent } from '../../components/modals/terms-of-use/terms-of-use.component'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { CandidatePoolNavComponent } from '@employer/app/components/custom-navs/candidate-pool-nav/candidate-pool-nav.component'
import { CandidateProfileShareModalComponent } from '@employer/app/modules/jobs/components/candidate-share-profile/candidate-profile-share-modal.component'
import { SortByComponent } from '@employer/app/components/sort-by/sort-by.component'
import { CandidatePoolMessagesNavComponent } from '@employer/app/components/nav-components/candidate-pool-messages-nav/candidate-pool-messages-nav.component'
import { EmployerCandidatePoolConversationDMProvider } from '@employer/app/config/conversation-config-provider/candidate-pool-conversation-dm-config.provider'

const declareExportComponents = [
  AppFooterComponent,
  AppLogoComponent,
  AppHeaderComponent,
  AppHeaderRegisterComponent,
  AppHeaderLandingComponent,
  AppHeaderLoginComponent,
  MainLayoutComponent,
  AppNavContainerComponent,
  FormSideoverContainerComponent,
  FormModalContainerComponent,
  UiTemplate,
  LoaderViewComponent,
  ModalPrivacyPolicyComponent,
  ModalTermsOfUseComponent,
  ResponsiveWarningComponent,
  UiRefinementListComponent,
  UiRangeSliderListComponent,
  UiAlgCardComponent,
  UiAlgLineItemComponent,
  SwimlaneWatchConfigFormComponent,
  SortByComponent,

  // Nav Components
  DirectMessagesNavComponent,
  JobApplicationMessagesNavComponent,

  CheckFieldIsUnfulfilledPipe,
  DocumentIdToContentIdPipe,
  IsApplicationArchivedPipe,
  ProfileDropdownComponent,
  MobileDrawerIconComponent,
  MessagingNavComponent,
  CustomerNavComponent,
  CheckStateRequiresSalaryTransparencyPipe,
  CandidatePoolNavComponent,
]

@NgModule({
  declarations: [...declareExportComponents],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule, // For header login modal
    UiComponentLibraryModule,
    TranslateModule,
    NgxSummernoteModule,
    E11SummernoteViewDirective,
    NgAisModule,
    SharedLibModule.forRoot(environment, ERROR_HANDLING),
    AccessModule.forRoot({ configProvider: AccessConfigProvider }),
    E11InputErrorsModule.forRoot({
      additional: {
        phoneNumber: 'Please enter a valid phone number',
        invalidDate: 'Please enter a valid date',
        invalidStartDate: 'Must be before end date',
        invalidEndDate: 'Must be after start date',
        requiredAuthCode: 'Please enter your supplied authorization code.',
        invalidAuthCode: "That doesn't appear to be a valid authorization code.",
        DateFuture: 'Cannot be a future date', // TODO consider how to get static type checking on these
        DateEmptyYear: 'Please pick a year', // TODO consider how to get static type checking on these
        passwordMatchError: 'The passwords do not match',
        hasWhitespaceError: 'This cannot start with a space',
        dateIsInRange: 'There is already a promotion that is running in that date range',
        notPastDate: 'Date must be today or in the future',
      },
    }),
    MessagingModule.forRoot({
      config: {
        provide: MessagingConfigurationProvider,
        useClass: MessagingConfigFromApp,
      },
      conversationCustomDataProvider: ConversationCustomDataProvider,
      conversationConfigProviders: [
        EmployerApplicationConversationDMProvider,
        EmployerApplicationConversationProvider,
        EmployerCandidatePoolConversationDMProvider,
        SocialDmConfigProvider,
      ],
    }),
    NotificationHandlerModule.forRoot({
      notificationHandlers: [
        VirtualDialogueSubmittedEmployerHandler,
        RequestedUpdateFulfilledNotificationHandler,
        HiringTeamMemberAddNotificationHandler,
        EmployerJobPostReactivatedNotificationHandler,
        EmployerJobPostClosedNotificationHandler,
        CandidateAppliedNotificationHandler,
        ApplicationCommentNotificationHandler,
        ApplicationReviewCreatedNotificationHandler,
        MessagingUserMentionedEmployerHandler,
        EmployerApplicationResubmittedHandler,
        SwimlaneStatusUpdatedHandler,
      ],
      configProvider: NotificationConfigProvider,
    }),
    LetDirective,
    WebUtilityModule,
    CustomerClientModule,
    E11SummernoteViewDirective,
    CompanySessionTimeoutComponent,
    UiCandidateStarsComponent,
    CandidateShareFormComponent,
    CandidateProfileShareModalComponent,
    CandidatePoolMessagesNavComponent,
  ],
  providers: [],
  exports: [
    ...declareExportComponents,
    UiComponentLibraryModule,
    TranslateModule,
    SharedLibModule,
    MessagingModule,
    NgxSummernoteModule,
    UiNotificationComponent,
    UiNotificationLongComponent,
    E11SummernoteViewDirective,
  ],
})
export class SharedModule {}
