import { CommonModule, DatePipe } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, ViewChild, inject } from '@angular/core'
import { IJobPostContentAssociation } from '@employer/app/models/job-post.model'
import { JobProfileStore } from '@employer/app/modules/jobs/stores/job-profile.store'
import { JobPostAutomationService } from '@employer/app/modules/jobs/views/job-automation/job-post-automation.service'
import { PermissionStore } from '@engineering11/access-web'
import { IMarkdownFile } from '@engineering11/ui-lib/e11-markdown-editor'
import { E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { ISelectOption } from '@engineering11/ui-lib/e11-select'
import { E11TabsModule } from '@engineering11/ui-lib/e11-tabs'
import { isNotNil } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { LetDirective } from '@ngrx/component'
import { TranslateModule } from '@ngx-translate/core'
import { isError } from 'lodash'
import { Observable, combineLatest, map } from 'rxjs'
import { IAutomationFormConfig, systemSenderOption } from '../../../automation-form-message'
import { JobAutomationPermissions } from '../../../automation.constants'
import { IMarkdownReceiptFile, ISendMessageFormData } from '../../../job-automation.model'
import { AutomationLoaderComponent } from '../../../partials/automation-loader'
import { IAutoQuestionFormData, IAutoQuestionMessageFormData, autoQuestionFormDefaults } from '../../auto-questions.model'
import { AutomatedQuestionMessage } from './automated-question-message'

@Component({
  selector: 'automated-question-messages-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [E11TabsModule, CommonModule, TranslateModule, DatePipe, AutomatedQuestionMessage, AutomationLoaderComponent, LetDirective],
  template: `
    <automation-loader [isLoading]="isUploadingAttachment"></automation-loader>
    <div class="e11-block e11-my-4"></div>
    @defer {
    <e11-tabs [tabsClassOverrides]="'e11-justify-start e11-gap-6'">
      <e11-tab [title]="'Initial Message' | translate">
        @defer {
        <ng-container *ngrxLet="{ formConfig: formConfig$ } as data">
          <automated-question-message
            #initialMessageForm
            [defaultValue]="autoQuestionFormDefaults.initialMessage"
            [formData]="formData?.initialMessage ?? autoQuestionFormDefaults.initialMessage"
            [formField]="'autoQuestionInitial'"
            [title]="formTitles.initialMessage | translate"
            [tooltipText]="formTooltipTexts.initialMessage | translate"
            [formConfig]="data.formConfig"
            [uploadAttachments]="uploadAttachments.bind(this)"
          ></automated-question-message>
        </ng-container>
        }
      </e11-tab>
      <e11-tab [title]="'Follow Up Message (Reminder)' | translate">
        @defer {
        <ng-container *ngrxLet="{ formConfig: formConfig$ } as data">
          <automated-question-message
            #followUpMessageForm
            [defaultValue]="autoQuestionFormDefaults.followUp"
            [formData]="formData?.followUp ?? autoQuestionFormDefaults.followUp"
            [formField]="'autoQuestionFollowUp'"
            [title]="formTitles.followUp | translate"
            [tooltipText]="formTooltipTexts.followUp | translate"
            [formConfig]="data.formConfig"
            [uploadAttachments]="uploadAttachments.bind(this)"
          ></automated-question-message>
        </ng-container>
        }
      </e11-tab>
      <e11-tab [title]="'Final Message (Warning)' | translate">
        @defer {
        <ng-container *ngrxLet="{ formConfig: formConfig$ } as data">
          <automated-question-message
            #finalMessageForm
            [defaultValue]="autoQuestionFormDefaults.warning"
            [formData]="formData?.warning ?? autoQuestionFormDefaults.warning"
            [formField]="'autoQuestionWarning'"
            [title]="formTitles.warning | translate"
            [tooltipText]="formTooltipTexts.warning | translate"
            [formConfig]="data.formConfig"
            [uploadAttachments]="uploadAttachments.bind(this)"
          ></automated-question-message>
        </ng-container>
        }
      </e11-tab>
    </e11-tabs>
    }
  `,
  styles: ``,
})
export class AutomatedQuestionMessagesFormComponent {
  @ViewChild('initialMessageForm') initialMessageForm!: AutomatedQuestionMessage
  @ViewChild('followUpMessageForm') followUpMessageForm!: AutomatedQuestionMessage
  @ViewChild('finalMessageForm') finalMessageForm!: AutomatedQuestionMessage

  isUploadingAttachment = false

  systemSenderOption = systemSenderOption
  autoQuestionFormDefaults = autoQuestionFormDefaults

  @Input() formData: IAutoQuestionFormData | undefined

  #jobProfileStore = inject(JobProfileStore)
  #logger = inject(E11Logger)

  permissionStore = inject(PermissionStore)

  canEditMessages$ = this.permissionStore.hasPermission(JobAutomationPermissions.CustomizeMessage)

  senderOptions$: Observable<ISelectOption[]> = this.#jobProfileStore.jobPostHiringTeam$.pipe(map(hiringTeamToSelectOptions))
  formConfig$: Observable<IAutomationFormConfig> = combineLatest([this.senderOptions$, this.canEditMessages$]).pipe(
    map(([senderOptions, canEditMessages]) => ({ senderOptions: senderOptions ?? [systemSenderOption], canEditMessages }))
  )

  formTitles = {
    initialMessage: 'The message is sent to the candidate to inform them they have questions expecting answers.',
    followUp: 'The message is sent to the candidate to remind them we asked them questions in a previous message.',
    warning:
      'This message is sent to the candidate as a warning that we have an outstanding request and if it is not handled in a timely manner, the candidate will no longer be considered for the job.',
  }
  formTooltipTexts = {
    initialMessage: 'This will reset the message content to what has been configured globally under your Company Settings.',
    followUp: 'This will reset the message content to what has been configured globally under your Company Settings.',
    warning: 'This will reset the message content to what has been configured globally under your Company Settings.',
  }

  constructor(private jobPostAutomationService: JobPostAutomationService, private notification: E11NotificationsService) {}

  async uploadAttachments(files: IMarkdownFile[]): Promise<IMarkdownReceiptFile[]> {
    this.isUploadingAttachment = true
    const response = await Promise.all(files.map(file => this.uploadAttachment(file)))
    this.isUploadingAttachment = false
    return response.filter(isNotNil)
  }

  async uploadAttachment(file: IMarkdownFile): Promise<IMarkdownReceiptFile | undefined> {
    try {
      const response = await this.jobPostAutomationService.uploadAttachment(file.file)
      if (!response) return
      return { id: file.id, receiptFile: response }
    } catch (error) {
      const errorTitle = `Error uploading attachment ${file.file.name}`
      const errorMessage = isError(error) ? error.message : `Error has occurred while uploading attachment ${file.file.name}`
      this.#logger.error(errorTitle, error)
      this.notification.popNotificationMessage({
        message: errorMessage,
        title: errorTitle,
        type: 'error',
      })
      return
    }
  }

  validateAndReturnData(): Partial<IAutoQuestionMessageFormData> | undefined {
    const initialMessage = this.initialMessageForm?.getData()
    const followUp = this.followUpMessageForm?.getData()
    const warning = this.finalMessageForm?.getData()

    interface IMessageData {
      initialMessage: ISendMessageFormData
      followUp: ISendMessageFormData
      warning: ISendMessageFormData
    }

    const messageData: IMessageData = {
      initialMessage,
      followUp,
      warning,
    }

    return messageData
  }
}

function hiringTeamToSelectOptions(hiringTeam?: Map<string, IJobPostContentAssociation>): ISelectOption[] {
  if (!hiringTeam) return [systemSenderOption]
  const hiringTeamMembers = Array.from(hiringTeam.values()).map(u => ({ name: `${u.firstName} ${u.lastName}`, value: u.userId }))
  return [systemSenderOption].concat(hiringTeamMembers)
}
