import { CommonModule } from '@angular/common'
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { IAutomationFormConfig, sendMessageForm } from '@employer/app/components/jobs-automation/automation-form-message'
import { AutomationDelayInfoComponent } from '@employer/app/components/jobs-automation/partials/automation-delay-info.component'
import { AutomationUpgradePromptComponent } from '@employer/app/components/jobs-automation/partials/automation-upgrade-prompt.component'
import { IFileReceipt } from '@engineering11/files-web'
import { E11DynamicFormBuilderComponent, E11DynamicFormModule, E11FormForType } from '@engineering11/ui-lib/e11-form-builder'
import { IMarkdownFile } from '@engineering11/ui-lib/e11-markdown-editor'
import { E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { TranslateModule } from '@ngx-translate/core'
import { validateFile } from '../../../automation.utils'
import { IMarkdownReceiptFile, ISendMessageFormData } from '../../../job-automation.model'
import { AutomationAttachmentPreviewComponent } from '../../../partials/automation-attachment-preview'
import { AutomationFormData, AutomationResetComponent, FormField } from '../../../partials/automation-reset.component'

@Component({
  selector: 'automated-question-message',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    E11DynamicFormModule,
    AutomationDelayInfoComponent,
    AutomationAttachmentPreviewComponent,
    AutomationUpgradePromptComponent,
    TranslateModule,
    E11TooltipModule,
    AutomationResetComponent,
  ],
  template: `
    <ng-container *ngIf="forms">
      <div class=" e11-mt-6 e11-mb-2">
        <span *ngIf="title"> {{ title }}</span>
      </div>
      <e11-dynamic-form-builder
        #messageForm
        [fieldGroups]="forms"
        [initialValue]="formData"
        [loading]="loading"
        [hideSubmitMenu]="true"
        (valueChanges)="handleFormChange($event)"
      >
        <ng-template e11Template="delayTimeHeaderTemplate">
          <automation-delay-info
            [toolTipText]="
              'This is how long you want to wait after the trigger is initiated before you send the notification to the candidate that they have questions needing their attention.'
            "
          ></automation-delay-info>
        </ng-template>
        <ng-template e11Template="upgradeProductTemplate">
          <automation-upgrade-prompt></automation-upgrade-prompt>
        </ng-template>
        <ng-template e11Template="resetFormTemplate">
          <automation-reset [formField]="formField" [loading]="loading" (onFormFieldReset)="onResetFormData(messageForm, $event)"></automation-reset>
        </ng-template>
        <ng-template e11Template="videoUrlPreviewTemplate">
          <automation-attachment-preview
            [fileReceipts]="messageData?.attachments"
            (removeFileReceipt)="removeFile($event)"
          ></automation-attachment-preview>
        </ng-template>
      </e11-dynamic-form-builder>
    </ng-container>
  `,
})
export class AutomatedQuestionMessage implements OnChanges, AfterViewInit {
  @Input() title: string = ''
  @Input() tooltipText: string = ''
  @Input() loading: boolean = false
  @Input() hideSubmitMenu: boolean = true

  @Input({ required: true }) formData!: ISendMessageFormData
  @Input({ required: true }) formField!: FormField
  @Input({ required: true }) defaultValue!: ISendMessageFormData
  @Input({ required: true }) uploadAttachments!: (files: IMarkdownFile[]) => Promise<IMarkdownReceiptFile[]>

  _formConfig?: IAutomationFormConfig
  @Input() set formConfig(config: IAutomationFormConfig) {
    this._formConfig = config
    if (this.defaultValue) {
      this.forms = this.buildForms(config)
    }
  }

  constructor(private cdr: ChangeDetectorRef, private notification: E11NotificationsService) {}

  removeFile(fileId: string) {
    if (!this.messageData?.attachments) return
    this.messageData = {
      ...this.messageData,
      attachments: (this.messageData?.attachments ?? []).filter(f => f.id !== fileId),
    }
    this.cdr.markForCheck()
  }

  forms?: E11FormForType<ISendMessageFormData>

  messageData: ISendMessageFormData = this.formData
  tempFiles: IMarkdownReceiptFile[] = []

  async handleFormChange(value: ISendMessageFormData) {
    this.messageData = {
      ...this.messageData,
      ...value,
    }
    this.cdr.detectChanges()
  }

  onResetFormData(form: E11DynamicFormBuilderComponent<ISendMessageFormData>, formData: AutomationFormData) {
    form.reset({ ...(formData as any), enabled: false })
    this.messageData = { ...(formData as any), enabled: false }
    this.tempFiles = []
  }

  private buildForms(config: IAutomationFormConfig) {
    return sendMessageForm(
      this.formData,
      { ...config, showStandaloneToggle: true, togglePosition: 'bottom' },
      {
        onFileUpload: this.onFileUpload.bind(this),
        onFileRemove: this.onFileRemove.bind(this),
      }
    )
  }

  async onFileUpload(markdownFiles: IMarkdownFile[]) {
    const newFiles = this.findNewFiles(markdownFiles)

    const validation = validateFile(newFiles, this.getAllAttachments())
    if (!validation.status) {
      this.notification.popNotificationMessage({
        message: validation.message ?? 'Error uploading video',
        type: 'error',
        title: validation.title ?? 'Error',
      })
      return
    }
    const tempFiles = await this.uploadAttachments(newFiles)
    this.tempFiles = tempFiles.concat(this.tempFiles)
    this.cdr.markForCheck()
  }

  onFileRemove(markdownFile: IMarkdownFile) {
    const tempFile = this.tempFiles.find(f => f.id === markdownFile.id)
    if (tempFile) {
      this.tempFiles = this.tempFiles.filter(f => f.id !== markdownFile.id)
    }
    this.cdr.markForCheck()
  }

  getAllAttachments(): IFileReceipt[] {
    return this.tempFiles.map(f => f.receiptFile).concat(this.messageData?.attachments ?? [])
  }

  resetTempFiles() {
    this.tempFiles = []
    this.cdr.markForCheck()
  }

  getData(): ISendMessageFormData {
    const data = {
      ...this.messageData,
      attachments: this.getAllAttachments(),
    }
    this.resetTempFiles()
    return data
  }

  findNewFiles(files: IMarkdownFile[]): IMarkdownFile[] {
    const tempFileIds = this.tempFiles.map(f => f.id)
    return files.filter(f => !tempFileIds.includes(f.id))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formData && this._formConfig) {
      this.forms = this.buildForms(this._formConfig)
      this.messageData = this.formData
      this.cdr.markForCheck()
    }
  }

  ngAfterViewInit(): void {
    if (this.defaultValue && this._formConfig) {
      this.forms = this.buildForms(this._formConfig)
      this.cdr.markForCheck()
    }
  }
}
